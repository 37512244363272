import {AssistentModel, AssistentenTyp} from './models';
import {DateUtils} from './date-utils';

export class AssistentFormatter {

  constructor(private assistent: AssistentModel) {
  }

  isGva() {
    return this.assistent.assistentenTyp === AssistentenTyp.GVA;
  }

  gsAssistent() {
    if (this.assistent.assistentenTyp === AssistentenTyp.GVA) {
      return this.isFrau() ? 'Geschäftsführerin' : 'Geschäftsführer';
    }
    return this.isFrau() ? 'Assistentin' : 'Assistent';
  }

  gsTheAssistent(uppercase = false) {
    let name = '';
    if (this.assistent.assistentenTyp === AssistentenTyp.GVA) {
      name = this.isFrau() ? 'die Geschäftsführerin' : 'der Geschäftsführer';
    } else {
      name = this.isFrau() ? 'die Assistentin' : 'der Assistent';
    }

    if (uppercase) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
    return name;
  }

  gsAssistentDativ() {
    if (this.assistent.assistentenTyp === AssistentenTyp.GVA) {
      return this.isFrau() ? 'der Geschäftsführerin' : 'dem Geschäftsführer';
    }
    return this.isFrau() ? 'der Assistentin' : 'dem Assistenten';
  }

  gsAssistentAkkusativ() {
    if (this.assistent.assistentenTyp === AssistentenTyp.GVA) {
      return this.isFrau() ? 'die Geschäftsführerin' : 'den Geschäftsführer';
    }
    return this.isFrau() ? 'die Assistentin' : 'den Assistenten';
  }

  gsYourAssistent() {
    if (this.assistent.assistentenTyp === AssistentenTyp.GVA) {
      return this.isFrau() ? 'Ihre Geschäftsführerin' : 'Ihr Geschäftsführer';
    }
    return this.isFrau() ? 'Ihre Assistentin' : 'Ihr Assistent';
  }

  gsIhremAssistent() {
    if (this.assistent.assistentenTyp === AssistentenTyp.GVA) {
      return this.isFrau() ? 'Ihrer Geschäftsführerin' : 'Ihrem Geschäftsführer';
    }
    return this.isFrau() ? 'Ihrer Assistentin' : 'Ihrem Assistenten';
  }

  isFrau() {
    return this.assistent.anrede === 'frau';
  }

  anredeTitelUndVorname() {
    return `${this.anrede()} ${this.titel()} ${this.assistent.vorname}`;
  }

  nameMitTitelUndAnrede() {
    return `${this.anrede()} ${this.nameMitTitel()}`;
  }

  nameMitTitel() {
    return `${this.titel()} ${this.name()}`.trim();
  }

  nameReverseMaxLetters(maxLetters = 55) {
    const name = this.nameReverseMitTitel();
    if (name.length > maxLetters) {
      const end = '...';
      return `${name.substr(0, maxLetters - end.length)}${end}`;
    } else {
      return name;
    }
  }

  nameReverseMitTitel() {
    return `${this.nameReverse()}`.trim();
  }

  name() {
    return `${this.assistent.vorname} ${this.assistent.nachname}`;
  }

  nameReverse() {
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return `${this.assistent.nachname}, ${this.assistent.vorname}${this.assistent.titel ? `, ${this.titel()}` : ''} `;
  }

  anrede() {
    return this.assistent.anrede.substr(0, 1).toUpperCase() + this.assistent.anrede.substring(1);
  }

  titel() {
    if (!this.assistent.titel) {
      return '';
    }
    return `${this.capitalize(this.assistent.titel)}.`;
  }

  geburtsdatum() {
    return DateUtils.toHumanReadableDate(this.assistent.geburtsdatum);
  }

  anschriftOhneLand() {
    return `${this.assistent.strasse} ${this.assistent.hausnummer}${this.assistent.strasseZusatz ? ', ' + this.assistent.strasseZusatz : ''}, ${this.assistent.postleitzahl} ${this.assistent.ort}`;
  }

  anschrift() {
    return `${this.assistent.strasse} ${this.assistent.hausnummer}${this.assistent.strasseZusatz ? ', ' + this.assistent.strasseZusatz : ''}, ${this.assistent.postleitzahl} ${this.assistent.ort}, ${this.land()}`;
  }

  land() {
    if (this.assistent.land === 'de') {
      return 'Deutschland';
    }
    if (this.assistent.land === 'at') {
      return 'Österreich';
    }
    if (this.assistent.land === 'ch') {
      return 'Schweiz';
    }
  }

  anschriftStrasse() {
    return `${this.assistent.strasse} ${this.assistent.hausnummer} ${this.assistent.strasseZusatz || ''}`.trim();
  }

  anschriftOrt() {
    return `${this.assistent.land.toUpperCase()} ${this.assistent.postleitzahl} ${this.assistent.ort}`;
  }

  vbVornameNachname(): string {
    return `${this.assistent.vbNachname ? this.assistent.vbNachname : ''}${this.assistent.vbVorname ? ', ' + this.assistent.vbVorname : ''}`;
  }

  vbNummer(): string {
    return `${this.assistent.vbNummer ? this.assistent.vbNummer : ''}`;
  }

  private capitalize(string: string): string {
    return string.substr(0, 1).toUpperCase() + string.substring(1);
  }
}
